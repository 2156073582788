import React from "react";
import ReactDOM from "react-dom/client";

const App = () => {
  return (
    <html>
      <head>
        <title>404 Not Found</title>
      </head>
      <body>
        <center>
          <h1>404 Not Found</h1>
        </center>
        <hr />
        <center>nginx</center>
      </body>
    </html>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
